import {
    FirmwareButton,
    FirmwareDeviceSubType,
    FirmwareDeviceType,
    FirmwareDeviceVersion,
    FirmwareEraseFlashButton,
} from "@features/FirmwareFeatures/FirmwareDevice";
import { useFirmwareCommand } from "@features/FirmwareFeatures/FirmwareDevice/model";
import FirmwareChangePortButton from "@features/FirmwareFeatures/FirmwareDevice/ui/FirmwareChangePortButton";
import FirmwareStopButton from "@features/FirmwareFeatures/FirmwareDevice/ui/FirmwareStopButton";
import { ESPLoader, Transport } from "@shared/esptool/esptool-js/lib";
import { useSerial } from "@shared/serial";
import { DefaultCard } from "@shared/ui/Cards";
import { DefaultOptionType } from "antd/es/select";
import { useRef, useState } from "react";
interface IFirmwareActionBar {}

const FirmwareActionBar = ({}: IFirmwareActionBar) => {
    const [subTypeOptions, setSubTypeOptions] = useState<DefaultOptionType[]>([]);
    const transport = useRef<Transport | undefined>();
    const esptool = useRef<ESPLoader>();
    const { closeSerial, changeSerialPort } = useSerial(transport, esptool);
    const { isActive, firmwareCommand, eraseClickCommand } = useFirmwareCommand(transport, esptool);
    return (
        <DefaultCard
            style={{
                height: "fit-content",
                margin: "0px 10px",
                minWidth: 300,
            }}
        >
            <FirmwareDeviceType setDeviceSubTypeOptions={setSubTypeOptions} />
            <FirmwareDeviceSubType deviceSubTypeOptions={subTypeOptions} />
            <FirmwareDeviceVersion />
            <FirmwareButton isActive={isActive} firmwareCommand={firmwareCommand} />
            <FirmwareEraseFlashButton isActive={isActive} eraseClickCommand={eraseClickCommand} />
            <FirmwareStopButton isActive={isActive} closeSerial={closeSerial} />
            <FirmwareChangePortButton isActive={isActive} changeSerialPort={changeSerialPort} />
        </DefaultCard>
    );
};

export default FirmwareActionBar;
