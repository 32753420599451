import { firmwareDeviceModel } from "@entities/FirmwareDevice";
import { Button } from "antd";
import { useSelector } from "react-redux";

interface IFirmwareButton extends IFirmwareButtonFeature {
    firmwareCommand?: () => void;
}

const FirmwareButton = ({ isActive, firmwareCommand }: IFirmwareButton) => {
    const isFirmwaringCommand = useSelector(firmwareDeviceModel.selectIsFirmwaringCommand);
    return (
        <Button
            loading={isFirmwaringCommand}
            disabled={isActive}
            onClick={firmwareCommand}
            type="primary"
            style={{ marginBottom: 8 }}
        >
            Прошить
        </Button>
    );
};

export default FirmwareButton;
