import { Button } from "antd";

interface IFirmwareChangePortButton extends IFirmwareButtonFeature {
    changeSerialPort: () => void;
}

const FirmwareChangePortButton = ({ isActive, changeSerialPort }: IFirmwareChangePortButton) => {
    return (
        <Button disabled={isActive} onClick={changeSerialPort}>
            Сменить порт
        </Button>
    );
};

export default FirmwareChangePortButton;
