import { MenuOutlined } from "@ant-design/icons";
import { Header } from "@shared/ui/Header";
import { Notification } from "@shared/ui/NotificationAndMessage";
import { Sidebar } from "@widgets/Sidebar";
import { Button, Layout } from "antd";
import { ReactNode, useState } from "react";
import { useSiteLayout } from "./UseSiteLayout";

interface ISiteLayout {
    children: ReactNode;
}
const SiteLayout = ({ children }: ISiteLayout) => {
    const [collapsedMenu, setCollapsedMenu] = useState(false);
    const windowWidth = window.innerWidth;
    const { routes } = useSiteLayout();
    const clickCollapseButton = () => setCollapsedMenu(!collapsedMenu);

    return (
        <Layout>
            <Sidebar
                // menuItems={oneTestRoute}
                menuItems={routes}
                collapsed={collapsedMenu}
            />
            <Layout className="site-layout" style={{ width: "100%", overflowY: "scroll" }}>
                {/* <Notification /> */}
                <Header collapse={collapsedMenu}>
                    <Button
                        type="text"
                        icon={collapsedMenu ? <MenuOutlined /> : <MenuOutlined />}
                        onClick={clickCollapseButton}
                        style={{
                            marginLeft: 10,
                            fontSize: "16px",
                            minWidth: 64,
                            height: 64,
                        }}
                    />
                </Header>
                <Notification />
                <Layout.Content
                    style={{
                        width: "100%",
                        padding: `16px ${windowWidth < 415 ? "0px" : "16px"} 0 ${
                            windowWidth < 415 ? "0px" : "16px"
                        }`,
                        paddingLeft: !collapsedMenu || window.innerWidth > 415 ? 16 : 0,
                    }}
                >
                    {children}
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default SiteLayout;
