export const getPreparedData = (flashAttempts: TFlashAttemptsData): TTableFlashAttemptDataType[] =>
    flashAttempts.map(
        (
            {
                id,
                user_id,
                user,
                type,
                subtype,
                status,
                version,
                created_at,
                updated_at,
                mac,
                device_type,
                device_subtype,
                comment,
            },
            index
        ) => {
            return {
                id,
                mac,
                type: device_type.name,
                subtype: device_subtype.name,
                userName: user?.name,
                version,
                status,
                created_at,
                updated_at,
                user_id,
                key: id,
                device_type,
                comment,
            };
        }
    );
