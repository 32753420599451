import { UploadOutlined } from "@ant-design/icons";
import { devicesModel } from "@entities/Devices";
import { Button } from "antd";
import { useDispatch } from "react-redux";

interface IUploadAdditionalFilesButton {
    row: TTableDataGroupedSubType;
    setIsOpen: (item: boolean) => void;
}

const UploadAdditionalFilesButton = ({ row, setIsOpen }: IUploadAdditionalFilesButton) => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(
            devicesModel.setEditingAdditionalFiles({
                device_type: row.device_type,
                subtype_name: row.subtype,
                additional_files: {
                    type_id: row.device_type,
                    subtype_name: row.subtype,
                },
            })
        );
    };

    return (
        <Button icon={<UploadOutlined />} onClick={onClick} style={{ width: 220 }}>
            Вспомогательные файлы
        </Button>
    );
};

export default UploadAdditionalFilesButton;
