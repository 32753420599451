import { $api, $apiAuth, clientId, clientSecret, grantType } from "@shared/api/api";

export const signIn = (username: string, password: string) => {
    return $apiAuth.post<TSignInResponse>("/oauth/token", {
        username,
        password,
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: grantType,
    });
};

export const getUserInfo = () => {
    return $apiAuth.get<TUserInfoResponse>("/api/user/get");
};

export const getAllUsers = () => {
    return $apiAuth.get<any>("/api/user/all");
};
export const getUsersByName = (name: string, userIds: number[] | undefined) => {
    return $api.get<any>("/api/users/", { params: { [name]: name } });
};
