import { firmwareDeviceModel } from "@entities/FirmwareDevice";
import { Button } from "antd";
import { useSelector } from "react-redux";

interface IFirmwareEraseFlashButton extends IFirmwareButtonFeature {
    eraseClickCommand?: () => void;
}

const FirmwareEraseFlashButton = ({ isActive, eraseClickCommand }: IFirmwareEraseFlashButton) => {
    const deviceVersion = useSelector(firmwareDeviceModel.selectCurrentVersion);
    const isErasingCommand = useSelector(firmwareDeviceModel.selectIsErasingCommand);

    return (
        <Button
            loading={isErasingCommand}
            disabled={isActive || !deviceVersion}
            onClick={eraseClickCommand}
            style={{ marginBottom: 8 }}
        >
            Очистить память
        </Button>
    );
};

export default FirmwareEraseFlashButton;
