import {
    deviceSubTypeOptions,
    deviceTypeOptions,
    firmwareDeviceApi,
} from "@entities/FirmwareDevice";
import { flashAttemptsModel } from "@entities/FlashAttempts";
import { useFilter } from "@features/Filters";
import { apiUrl } from "@shared/api/api";
import { FilterKit } from "@shared/ui/Filters";
import { getValidParams } from "@shared/ui/Filters/lib";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const FlashAttemptsTableFilters = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [subTypeOptions, setSubTypeOptions] = useState<{ label: string; value: any }[]>([]);
    const request = (values: any, reset: boolean) => {
        const conditions: any[] = getValidParams(values);
        const parameters: TMultipleCondition = {
            condition_type: "AND",
            conditions: conditions.length > 0 ? conditions : [],
        };
        if (!reset && parameters) {
            dispatch(
                flashAttemptsModel.loadFlashAttemptsHistoryThunk(
                    parameters.conditions.length > 0 ? { filter: parameters } : {}
                )
            );
        } else {
            dispatch(flashAttemptsModel.loadFlashAttemptsHistoryThunk({}));
        }
    };
    const initialState: any = {
        mac: null,
        type: null,
        subtype: null,
        version: null,
        status: null,
        created_at: null,
        updated_at: null,
        user_id: null,
    };
    const { values, onChange, onReset, onSubmit, setValues } =
        useFilter<TTableFlashAttemptDataType>(initialState, request);
    useEffect(() => {
        if (values.type) {
            firmwareDeviceApi
                .getDevicesSubTypes(values.type)
                .then(({ data: { data } }) => {
                    const deviceSubTypesOptions = Object.entries(data[values.type]).map(
                        ([key, value]) => ({
                            label: key,
                            value: key,
                        })
                    );
                    setSubTypeOptions(deviceSubTypesOptions);
                })
                .catch((e: any) => {
                    console.log(e);
                });
            setValues({ ...values, subtype: null });
        }
    }, [values.type]);
    return (
        <FilterKit
            onSubmit={onSubmit}
            onChange={onChange}
            onReset={onReset}
            fields={[
                {
                    type: "input",
                    label: "MAC-адрес устройства",
                    name: "mac",
                    value: values.mac,
                },
                {
                    type: "select",
                    label: "Тип устройства",
                    name: "type",
                    value: values.type,
                    options: deviceTypeOptions,
                    onChangeCustom: (value: string) => {
                        onChange({ type: value, subtype: null });
                    },
                },
                {
                    type: "select",
                    label: "Подтип устройства",
                    name: "subtype",
                    value: values.subtype,
                    options: values.type ? subTypeOptions : deviceSubTypeOptions,
                },
                {
                    type: "input",
                    label: "Версия прошивки",
                    name: "version",
                    value: values.version,
                },
                {
                    type: "select",
                    label: "Статус прошивки",
                    name: "status",
                    value: values.status,
                    options: [
                        { label: "Успешно", value: "success" },
                        { label: "Не успешно", value: "failed" },
                    ],
                },
                {
                    type: "requestedSelect",
                    label: "Ответственный",
                    name: "user_id",
                    value: values.user_id ?? [],
                    requestUrl: `${apiUrl}/api/users`,
                    queryTypedParam: "name",
                    queryChoosenParams: "userIds",
                    valueLabel: "name",
                    valueKey: "id",
                },
            ]}
        />
    );
};

export default FlashAttemptsTableFilters;
