import { deviceTypeOptions } from "@entities/FirmwareDevice";
import { FormItem } from "@shared/ui/Form";
import { NotFoundContent } from "@shared/ui/NotFoundContent";
import { Input, Modal, Select } from "antd";
import { useFlashAttemptsModal } from "../model";
import { UserAccess } from "@entities/User";

interface IFlashAttemptsModal {}

const FlashAttemptsModal = ({}: IFlashAttemptsModal) => {
    const { editingFlashAttempt, subTypeOptions, error, onClose, onChange, onOk } =
        useFlashAttemptsModal();

    return (
        <div>
            <Modal
                title={"Редактирование записи прошивки"}
                open={!!editingFlashAttempt}
                onCancel={onClose}
                onOk={onOk}
            >
                <UserAccess.AdminAccess>
                    <FormItem error={!!error.response?.data?.data?.["mac"]} label="MAC-адрес">
                        <Input
                            value={editingFlashAttempt?.mac}
                            onChange={(event) => onChange("mac", event.target.value)}
                        />
                    </FormItem>
                    <FormItem label="Тип устройства">
                        <Select
                            style={{ width: "100%" }}
                            value={editingFlashAttempt?.type}
                            onSelect={(value) => onChange("type", value)}
                            options={deviceTypeOptions}
                        />
                    </FormItem>
                    <FormItem
                        label="Подтип устройства"
                        error={!!error.response?.data?.data?.["subtype"]}
                    >
                        <Select
                            notFoundContent={
                                <NotFoundContent condition={deviceTypeOptions.length < 1} />
                            }
                            style={{ width: "100%" }}
                            value={editingFlashAttempt?.subtype}
                            onSelect={(value) => onChange("subtype", value)}
                            options={subTypeOptions}
                        />
                    </FormItem>
                    <FormItem
                        label="Версия прошивки"
                        error={!!error.response?.data?.data?.["version"]}
                    >
                        <Input
                            value={editingFlashAttempt?.version}
                            onChange={(event) => onChange("version", event.target.value)}
                        />
                    </FormItem>
                    <FormItem label="Статус прошивки">
                        <Select
                            style={{ width: "100%" }}
                            value={editingFlashAttempt?.status}
                            onSelect={(value) => onChange("status", value)}
                            options={[
                                { value: "success", label: "Успешно" },
                                { value: "failed", label: "Не успешно" },
                            ]}
                        />
                    </FormItem>
                </UserAccess.AdminAccess>
                <FormItem label="Комментарий">
                    <Input.TextArea
                        style={{ width: "100%" }}
                        value={editingFlashAttempt?.comment ?? undefined}
                        onChange={(event) => onChange("comment", event.target.value)}
                    />
                </FormItem>
            </Modal>
        </div>
    );
};

export default FlashAttemptsModal;
