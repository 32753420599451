import { firmwareDeviceApi } from "@entities/FirmwareDevice";
import { flashAttemptsModel } from "@entities/FlashAttempts";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useFlashAttemptsModal = () => {
    const editingFlashAttempt = useSelector(flashAttemptsModel.selectEditingFlashAttempt);
    const [error, setError] = useState<TValidationError>({});
    const dispatch = useDispatch<AppDispatch>();
    const [subTypeOptions, setSubTypeOptions] = useState<
        {
            value: string;
            label: string;
        }[]
    >([]);

    useEffect(() => {
        if (editingFlashAttempt && subTypeOptions.length < 1) {
            firmwareDeviceApi
                .getDevicesSubTypes(editingFlashAttempt.type)
                .then(({ data: { data } }) => {
                    const deviceSubTypesOptions = Object.entries(
                        data[editingFlashAttempt.type]
                    ).map(([key, value]) => ({
                        label: key,
                        value: key,
                        item: value,
                    }));
                    setSubTypeOptions(deviceSubTypesOptions);
                });
        }
    }, [editingFlashAttempt?.type]);

    const onClose = () => {
        dispatch(flashAttemptsModel.setEditingFlashAttempt(null));
        setError({});
    };
    const onChange = (name: string, value: any) => {
        if (editingFlashAttempt) {
            if (name === "type") {
                firmwareDeviceApi
                    .getDevicesSubTypes(value)
                    .then(({ data: { data } }) => {
                        const deviceSubTypesOptions = Object.entries(data[value]).map(
                            ([key, value]) => ({
                                label: key,
                                value: key,
                                item: value,
                            })
                        );
                        setSubTypeOptions([...deviceSubTypesOptions]);
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
                dispatch(
                    flashAttemptsModel.setEditingFlashAttempt({
                        ...editingFlashAttempt,
                        subtype: "",
                        type: value,
                    })
                );
            } else {
                dispatch(
                    flashAttemptsModel.setEditingFlashAttempt({
                        ...editingFlashAttempt,
                        [name]: value,
                    })
                );
            }
        }
    };

    const onOk = () => {
        setError({});
        dispatch(
            flashAttemptsModel.updateFlashAttemptHistoryThunk({
                id: editingFlashAttempt.id,
                data: editingFlashAttempt,
            })
        )
            .then(parseFormResponse)
            .then(() => {
                onClose();
                notificationEmit({
                    title: "Изменение истории прошивки",
                    description: `История прошвки устройства №${editingFlashAttempt.id} изменена  `,
                    type: "info",
                });
            })
            .catch((error: TValidationError) => {
                console.log(error);
                setError(error);
                notificationEmit({
                    error: error,
                    title: error.message ?? "Ошибка",
                    type: "error",
                });
            });
    };
    return {
        editingFlashAttempt,
        subTypeOptions,
        error,
        onClose,
        onChange,
        onOk,
    };
};
