import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    loadFlashAttemptsHistoryThunk,
    updateFlashAttemptHistoryThunk,
    deleteFlashAttemptHistoryThunk,
    deleteManyFlashAttemptHistoryThunk,
} from "./FlashAttemptsThunk";

export interface IFlashAttempts {
    flashAttempts: TFlashAttemptsData;
    editingFlashAttempt: TFlashAttemptData | null;
    isLoading: boolean;
}

const initialState: IFlashAttempts = {
    flashAttempts: [],
    editingFlashAttempt: null,
    isLoading: true,
};

export const flashAttemptsSlice = createSlice({
    name: "flashAttempts",
    initialState,
    reducers: {
        setInitialData: (state, action: PayloadAction<TFlashAttemptsData>) => {
            state.flashAttempts = action.payload;
        },
        setEditingFlashAttempt: (state, action: PayloadAction<TFlashAttemptData | null>) => {
            state.editingFlashAttempt = action.payload;
        },
    },
    extraReducers: (builder) => {
        loadFlashAttemptsHistoryBuilder(builder);
        updateFlashAttemptHistoryBuilder(builder);
        deleteFlashAttemptHistoryBuilder(builder);
        deleteManyFlashAttemptHistoryBuilder(builder);
    },
});

const loadFlashAttemptsHistoryBuilder = (builder: ActionReducerMapBuilder<IFlashAttempts>) => {
    builder.addCase(loadFlashAttemptsHistoryThunk.fulfilled, (state, action) => {
        state.flashAttempts = action.payload;
        state.isLoading = false;
    });
    builder.addCase(loadFlashAttemptsHistoryThunk.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(loadFlashAttemptsHistoryThunk.rejected, (state, rejectedValue) => {
        state.isLoading = false;
    });
};
const updateFlashAttemptHistoryBuilder = (builder: ActionReducerMapBuilder<IFlashAttempts>) => {
    builder.addCase(updateFlashAttemptHistoryThunk.fulfilled, (state, action) => {
        const updatedFlashAttempt = action.payload;
        state.flashAttempts = state.flashAttempts.map((attempt) =>
            attempt.id === updatedFlashAttempt.id ? updatedFlashAttempt : attempt
        );
        state.isLoading = false;
    });
    builder.addCase(updateFlashAttemptHistoryThunk.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(updateFlashAttemptHistoryThunk.rejected, (state, rejectedValue) => {
        state.isLoading = false;
    });
};
const deleteFlashAttemptHistoryBuilder = (builder: ActionReducerMapBuilder<IFlashAttempts>) => {
    builder.addCase(deleteFlashAttemptHistoryThunk.fulfilled, (state, action) => {
        const deletedFlashAttemptId = action.payload;
        console.log(deletedFlashAttemptId);
        state.flashAttempts = state.flashAttempts.filter(
            (attempt) => attempt.id !== deletedFlashAttemptId
        );
        state.isLoading = false;
    });
    builder.addCase(deleteFlashAttemptHistoryThunk.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(deleteFlashAttemptHistoryThunk.rejected, (state, rejectedValue) => {
        state.isLoading = false;
    });
};
const deleteManyFlashAttemptHistoryBuilder = (builder: ActionReducerMapBuilder<IFlashAttempts>) => {
    builder.addCase(deleteManyFlashAttemptHistoryThunk.fulfilled, (state, action) => {
        const deletedFlashAttemptIds = action.payload;
        state.flashAttempts = state.flashAttempts.filter(
            (attempt) => !deletedFlashAttemptIds.includes(attempt.id)
        );
        state.isLoading = false;
    });
    builder.addCase(deleteManyFlashAttemptHistoryThunk.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(deleteManyFlashAttemptHistoryThunk.rejected, (state, rejectedValue) => {
        state.isLoading = false;
    });
};

export const { setInitialData, setEditingFlashAttempt } = flashAttemptsSlice.actions;

export default flashAttemptsSlice.reducer;
