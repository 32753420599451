import { ControlOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { TInputField } from "../FilterKitTypes";
import { useFilterKit } from "../model";
import FilterInputItems from "./FilterInputItems";
import "./FilterKit.scss";
import FilterModal from "./FilterModal";

interface IFilterKit {
    fields: TInputField[];
    onChange: (newValue: { [name: string]: any }, requestAfterDelay?: boolean) => void;
    onReset: () => void;
    onSubmit: () => void;
}

const FilterKit = ({ onSubmit, fields, onChange, onReset }: IFilterKit) => {
    const { open, isModal, onClose, setOpen } = useFilterKit(onChange);
    const filterKitFields = fields.filter((field) => !field.hide);

    const onChangeSendRequest = (newValue: { [name: string]: any }) => {
        onChange(newValue, false);
    };

    if (isModal && filterKitFields.length > 1) {
        return (
            <div className="filter-fields--modal">
                <Button
                    icon={<ControlOutlined />}
                    onClick={() => setOpen(true)}
                    style={{ marginBottom: 24, marginRight: 8 }}
                >
                    Фильтры
                </Button>
                <Button type="text" onClick={onReset}>
                    Сбросить
                </Button>
                <FilterModal
                    open={open}
                    onClose={onClose}
                    onReset={onReset}
                    onSubmit={onSubmit}
                    fields={filterKitFields}
                    onChange={onChange}
                />
            </div>
        );
    } else {
        return (
            <div className="filter-fields">
                <div className="filter-fields__body">
                    {filterKitFields.map((field: TInputField, index: number) => {
                        return (
                            <div className="filter-fields__item" key={index}>
                                <FilterInputItems field={field} onChange={onChangeSendRequest} />
                            </div>
                        );
                    })}
                    <div className="filter-fields__item">
                        <Button style={{ marginTop: 4 }} onClick={onReset}>
                            Сбросить фильтры
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
};

export default FilterKit;
