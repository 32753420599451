import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    changeFlashAttemptInfo,
    deleteFlashAttempt,
    deleteManyFlashAttempts,
    getFlashAttemptsHistory,
} from "../api";

export const loadFlashAttemptsHistoryThunk = createAsyncThunk(
    "flashAttemptsSlice/loadFlashAttemptsHistory",
    async (params: TParams, { rejectWithValue }) => {
        try {
            const response = await getFlashAttemptsHistory(params);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateFlashAttemptHistoryThunk = createAsyncThunk(
    "flashAttemptsSlice/updateFlashAttemptHistory",
    async ({ id, data }: { id: number; data: TFlashAttemptData }, { rejectWithValue }) => {
        try {
            const response = await changeFlashAttemptInfo(id, data);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteFlashAttemptHistoryThunk = createAsyncThunk(
    "flashAttemptsSlice/deleteFlashAttemptHistory",
    async (id: number, { rejectWithValue }) => {
        try {
            await deleteFlashAttempt(id);
            return id;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteManyFlashAttemptHistoryThunk = createAsyncThunk(
    "flashAttemptsSlice/deleteManyFlashAttemptHistory",
    async (ids: React.Key[], { rejectWithValue }) => {
        try {
            await deleteManyFlashAttempts(ids);
            return ids;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
