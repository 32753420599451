import { flashAttemptsModel } from "@entities/FlashAttempts";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useDispatch } from "react-redux";

export const useFlashAttemptsActions = (row: TFlashAttemptData) => {
    const dispatch = useDispatch<AppDispatch>();

    const onEditRowClick = () => {
        dispatch(
            dispatch(
                flashAttemptsModel.setEditingFlashAttempt({ ...row, type: row.device_type.id })
            )
        );
    };

    const deleteRow = (id: number) => {
        dispatch(flashAttemptsModel.deleteFlashAttemptHistoryThunk(id))
            .then(parseFormResponse)
            .then(() => {
                notificationEmit({
                    title: "Изменение истории прошивки",
                    description: `История прошвки устройства №${id} удалена`,
                    type: "info",
                });
            })
            .catch((e: any) =>
                notificationEmit({
                    title: "Изменение истории прошивки",
                    description: `Не удалось удалить историю прошивки устройства №${id}`,
                    type: "error",
                })
            );
    };
    const onDeleteRowClick = () => {
        deleteRow(row.id);
    };

    return {
        onDeleteRowClick,
        onEditRowClick,
    };
};
