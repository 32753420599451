import { FormItem } from "@shared/ui/Form";
import { TFilterInputItem } from "../../FilterKitTypes";
import TextArea from "antd/es/input/TextArea";

const FilterTextAreaItem = ({ field, onChange }: TFilterInputItem) => {
    const { value, name, label } = field;
    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        onChange({ [name]: value });
    };
    return (
        <FormItem label={label}>
            <>
                <TextArea name={name} onChange={onChangeInput} value={value} />
            </>
        </FormItem>
    );
};

export default FilterTextAreaItem;
