import { firmwareDeviceModel } from "@entities/FirmwareDevice";
import { Button } from "antd";
import { useSelector } from "react-redux";

interface IFirmwareStopButton extends IFirmwareButtonFeature {
    closeSerial: () => void;
}

const FirmwareStopButton = ({ isActive, closeSerial }: IFirmwareStopButton) => {
    const isFirmwaringOperation = useSelector(firmwareDeviceModel.selectIsFirmwaringOperation);
    return (
        <Button disabled={isFirmwaringOperation} onClick={closeSerial} style={{ marginBottom: 8 }}>
            Отменить
        </Button>
    );
};

export default FirmwareStopButton;
