import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { UserAccess } from "@entities/User";
import { Button, Popconfirm } from "antd";
import { CSSProperties } from "react";
import { useFlashAttemptsActions } from "../model";

interface IFlashAttemptsActions {
    selectedIds: React.Key[];
    row: TFlashAttemptData;
}

const FlashAttemptsActions = ({ selectedIds, row }: IFlashAttemptsActions) => {
    const { onDeleteRowClick, onEditRowClick } = useFlashAttemptsActions(row);
    const buttonStyle: CSSProperties = { margin: 0, width: "fit-content", padding: 0 };

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <UserAccess.OwnerAccess id={row.user_id}>
                <Button
                    icon={<EditOutlined />}
                    type="link"
                    style={{ ...buttonStyle, marginRight: 16 }}
                    onClick={onEditRowClick}
                />
            </UserAccess.OwnerAccess>
            <UserAccess.AdminAccess>
                <Popconfirm
                    title="Удалить строку?"
                    okText="Удалить"
                    onConfirm={onDeleteRowClick}
                    cancelText="Отмена"
                >
                    <Button
                        icon={<DeleteOutlined style={{ color: "red" }} />}
                        type="link"
                        style={{ ...buttonStyle, color: "red" }}
                    />
                </Popconfirm>
            </UserAccess.AdminAccess>
        </div>
    );
};

export default FlashAttemptsActions;
