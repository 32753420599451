import { createAsyncThunk } from "@reduxjs/toolkit";
import { getVersions, updateVersion, getAdditionalFiles, uploadAdditionalFiles } from "../api";

export const loadVersionsThunk = createAsyncThunk(
    "DevicesSlice/loadVersions",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getVersions();
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateVersionThunk = createAsyncThunk(
    "DevicesSlice/updateVersion",
    async (updatedSubType: TTableDataGroupedSubType, { rejectWithValue }) => {
        try {
            const response = await updateVersion(updatedSubType);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const loadAdditionalFilesThunk = createAsyncThunk(
    "DevicesSlice/loadAdditionalFiles",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getAdditionalFiles();
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateAdditionalFilesThunk = createAsyncThunk(
    "DevicesSlice/updateAdditionalFiles",
    async (
        { formData, addresses }: { formData: FormData; addresses: string[] | null },
        { rejectWithValue }
    ) => {
        try {
            const response = await uploadAdditionalFiles(formData);
            return {
                data: response.data.data,
                type_id: formData.get("type_id"),
                subtype_name: formData.get("subtype_name"),
                addresses: addresses,
                // bootloader: formData.get('bootloader')
            };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteAdditionalFilesThunk = createAsyncThunk(
    "DevicesSlice/deleteAdditionalFiles",
    async (formData: FormData, { rejectWithValue }) => {
        try {
            const response = await uploadAdditionalFiles(formData);
            return {
                data: response.data.data,
                type_id: formData.get("type_id"),
                subtype_name: formData.get("subtype_name"),
            };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
