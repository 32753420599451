import { flashAttemptsModel } from "@entities/FlashAttempts";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useDispatch } from "react-redux";

export const useFlashAttemptsTable = (
    selectedIds: React.Key[],
    setSelectedIds: (item: React.Key[]) => void
) => {
    const dispatch = useDispatch<AppDispatch>();

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedIds(newSelectedRowKeys);
    };

    const onClickDeleteManyFlashAttempts = () => {
        dispatch(flashAttemptsModel.deleteManyFlashAttemptHistoryThunk(selectedIds))
            .then(parseFormResponse)
            .then(() => {
                notificationEmit({
                    type: "success",
                    title: "Выбранные записи удалены",
                });
            })
            .catch((e: any) => {
                notificationEmit({
                    type: "error",
                    title: "Не удалось удалить выбранные записи",
                });
            });
        setSelectedIds([]);
    };

    return {
        onSelectChange,
        onClickDeleteManyFlashAttempts,
    };
};
