import { ESPLoader, Transport } from "@shared/esptool/esptool-js/lib";
import { useTerminalEmits } from "@shared/ui/Terminal";
import { PORT_NOT_SELECTED_ERROR } from "./constants";

export const useSerial = (
    transport: React.MutableRefObject<Transport | undefined>,
    esptool: React.MutableRefObject<ESPLoader | undefined>
) => {
    const terminal = useTerminalEmits("firmware");
    const requestSerialPort = async () => {
        if (transport.current === undefined) {
            const serial = await navigator.serial.requestPort();
            transport.current = new Transport(serial, true, false);
        }

        return transport.current;
    };
    const closeSerial = async () => {
        try {
            await transport.current?.disconnect();
        } catch (e: any) {}
        transport.current = undefined;
    };

    const getEsptool = async (flashOptions: any) => {
        esptool.current = new ESPLoader({ ...flashOptions });
        await esptool.current.main();
        return esptool.current;
    };

    const changeSerialPort = async () => {
        try {
            await closeSerial();
            await requestSerialPort();
        } catch (error: any) {
            if (error.code === PORT_NOT_SELECTED_ERROR)
                terminal.addErrorMessageEmit("Порт не выбран.");
            return null;
        }
    };

    return {
        requestSerialPort,
        closeSerial,
        getEsptool,
        changeSerialPort,
    };
};
