import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IFirmwareDevice {
    currentDeviceType: TCurrentDeviceType;
    currentDeviceSubType: TCurrentDeviceSubType;
    currentVersion: TCurrentVersion;
    transport: TTransport;
    baudrate: TBaudrate;
    espLoader: TEspLoader;
    chip: TChip;
    device: any;
    isFirmwaringOperation: boolean;
    isFirmwaringCommand: boolean;
    isErasingCommand: boolean;
}

const initialState: IFirmwareDevice = {
    currentDeviceType: null,
    currentDeviceSubType: null,
    currentVersion: null,
    transport: null,
    baudrate: 921600,
    espLoader: null,
    chip: null,
    device: null,
    isFirmwaringOperation: false,
    isFirmwaringCommand: false,
    isErasingCommand: false,
};

export const firmwareDeviceSlice = createSlice({
    name: "firmwareDevice",
    initialState,
    reducers: {
        setIsFirmwaringOperation: (state, action: PayloadAction<boolean>) => {
            state.isFirmwaringOperation = action.payload;
        },
        setIsFirmwaringCommand: (state, action: PayloadAction<boolean>) => {
            state.isFirmwaringCommand = action.payload;
        },
        setIsErasingCommand: (state, action: PayloadAction<boolean>) => {
            state.isErasingCommand = action.payload;
        },
        setCurrentDeviceType: (state, action: PayloadAction<TCurrentDeviceType>) => {
            state.currentDeviceType = action.payload;
        },
        setCurrentDeviceSubType: (state, action: PayloadAction<TCurrentDeviceSubType>) => {
            state.currentDeviceSubType = action.payload;
        },
        setCurrentVersion: (state, action: PayloadAction<TCurrentVersion>) => {
            state.currentVersion = action.payload;
        },
        setTransport: (state, action: PayloadAction<TTransport>) => {
            state.transport = action.payload;
        },
        setBaudrate: (state, action: PayloadAction<TBaudrate>) => {
            state.baudrate = action.payload;
        },
        setEspLoader: (state, action: PayloadAction<TEspLoader>) => {
            state.espLoader = action.payload;
        },
        setChip: (state, action: PayloadAction<TChip>) => {
            state.chip = action.payload;
        },
        setDevice: (state, action: PayloadAction<any>) => {
            state.device = action.payload;
        },
    },
});

export const {
    setIsFirmwaringOperation,
    setIsFirmwaringCommand,
    setIsErasingCommand,
    setCurrentDeviceType,
    setCurrentDeviceSubType,
    setCurrentVersion,
    setTransport,
    setBaudrate,
    setEspLoader,
    setChip,
    setDevice,
} = firmwareDeviceSlice.actions;

export default firmwareDeviceSlice.reducer;
