import { FlashAttempts, flashAttemptsModel } from "@entities/FlashAttempts";
import { UserAccess, userModel } from "@entities/User";
import { Button, TableColumnsType } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getPreparedData } from "../lib";
import { useFlashAttemptsTable } from "../model";
import FlashAttemptTime from "./FlashAttemptTime";
import FlashAttemptsActions from "./FlashAttemptsActions";
import FlashAttemptsModal from "./FlashAttemptsModal";

const FlashAttemptsTable = () => {
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const isLoading = useSelector(flashAttemptsModel.selectFlashAttemptsIsLoading);
    const { onSelectChange, onClickDeleteManyFlashAttempts } = useFlashAttemptsTable(
        selectedIds,
        setSelectedIds
    );
    const { flashAttempts } = flashAttemptsModel.useLoadFlashAttempts(true);

    const preparedData = getPreparedData(flashAttempts);

    const columns: TableColumnsType<TTableFlashAttemptDataType> = [
        { title: "№", dataIndex: "id", key: "id", width: 60 },
        { title: "Ответственный", dataIndex: "userName", key: "userName", width: 200 },
        { title: "MAC-адрес устройства", dataIndex: "mac", key: "mac", width: 200 },
        {
            title: "Дата прошивки",
            dataIndex: "created_at",
            key: "created_at",
            width: 200,
            sorter: (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
            render: (text: string) => <FlashAttemptTime time={text} />,
        },
        {
            title: "Дата изменения",
            dataIndex: "updated_at",
            key: "updated_at",
            width: 200,
            sorter: (a, b) => Date.parse(a.updated_at) - Date.parse(b.updated_at),
            render: (text: string) => <FlashAttemptTime time={text} />,
        },
        { title: "Тип устройства", dataIndex: "type", key: "type", width: 150 },
        { title: "Подтип устройства", dataIndex: "subtype", key: "subtype", width: 150 },
        {
            title: "Версия прошивки",
            dataIndex: "version",
            key: "version",
            width: 150,
        },
        {
            title: "Статус прошивки",
            dataIndex: "status",
            key: "status",
            width: 200,
            render: (value) => (value === "success" ? "Успешно" : "Не успешно"),
        },
        { title: "Комментарий", dataIndex: "comment", key: "comment", width: 300 },
        {
            title: "Действия",
            dataIndex: "",
            key: "",
            render: (text) => <FlashAttemptsActions selectedIds={selectedIds} row={text} />,
        },
    ];
    const isUserAdmin = useSelector(userModel.selectUserIsAdmin);

    return (
        <div style={{ width: "100%", flexGrow: 1 }}>
            <FlashAttemptsModal />
            <UserAccess.AdminAccess>
                <Button
                    disabled={selectedIds.length < 1}
                    style={{ marginBottom: 8 }}
                    onClick={onClickDeleteManyFlashAttempts}
                    danger
                >
                    Удалить выбранные элементы
                </Button>
            </UserAccess.AdminAccess>
            <FlashAttempts
                multiSelectable={isUserAdmin}
                data={preparedData}
                columns={columns}
                isLoading={isLoading}
                selectedIds={selectedIds}
                onSelectChange={onSelectChange}
            />
        </div>
    );
};

export default FlashAttemptsTable;
