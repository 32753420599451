import type { TableProps } from "antd";
import { Table } from "antd";
import "./FlashAttempts.scss";
interface IFlashAttempts {
    multiSelectable: boolean;
    data: TTableFlashAttemptDataType[];
    columns: TableProps<TTableFlashAttemptDataType>["columns"];
    isLoading: boolean;
    selectedIds: React.Key[] | undefined;
    onSelectChange: (item: React.Key[]) => void;
    onChange?: TableProps<TTableFlashAttemptDataType>["onChange"];
}

const FlashAttempts = ({
    multiSelectable,
    data,
    columns,
    isLoading,
    selectedIds,
    onSelectChange,
    onChange,
}: IFlashAttempts) => {
    const rowSelection = {
        selectedIds,
        onChange: onSelectChange,
    };

    return (
        <div className="table-wrapper">
            <div className="table-wrapper-inner">
                <Table
                    scroll={{ x: 2000 }}
                    tableLayout="fixed"
                    pagination={{ position: ["bottomLeft"] }}
                    rowSelection={multiSelectable ? rowSelection : undefined}
                    loading={isLoading}
                    columns={columns}
                    dataSource={data}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default FlashAttempts;
