import { DevicesPage } from "@pages/Devices";
import { FirmwarePage } from "@pages/FirmwarePage";
import { FlashAttemptsPage } from "@pages/FlashAttemptsPage";
import { Route, Routes } from "react-router-dom";
import { SiteLayout } from "../layout";

const AuthorizedRouter = () => {
    return (
        <SiteLayout>
            <Routes>
                <Route path="/firmware-device" element={<FirmwarePage />} />
                <Route path="/flash-attempts" element={<FlashAttemptsPage />} />
                <Route path="/devices-versions" element={<DevicesPage />} />
            </Routes>
        </SiteLayout>
    );
};

export default AuthorizedRouter;
