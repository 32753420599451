import { Transport } from "@shared/esptool/esptool-js/lib";

export const selectCurrentDeviceType = (state: RootState): TCurrentDeviceType =>
    state.firmwareDevice.currentDeviceType;
export const selectCurrentDeviceSubType = (state: RootState): TCurrentDeviceSubType =>
    state.firmwareDevice.currentDeviceSubType;
export const selectIsFirmwaringOperation = (state: RootState): boolean =>
    state.firmwareDevice.isFirmwaringOperation;
export const selectIsFirmwaringCommand = (state: RootState): boolean =>
    state.firmwareDevice.isFirmwaringCommand;
export const selectIsErasingCommand = (state: RootState): boolean =>
    state.firmwareDevice.isErasingCommand;
export const selectCurrentVersion = (state: RootState): TCurrentVersion =>
    state.firmwareDevice.currentVersion;
export const selectCurrentTransport = (state: RootState): Transport =>
    state.firmwareDevice.transport;
export const selectCurrentBaudrate = (state: RootState): TBaudrate => state.firmwareDevice.baudrate;
export const selectCurrentEspLoader = (state: RootState): TEspLoader =>
    state.firmwareDevice.espLoader;
export const selectCurrentChip = (state: RootState): TChip => state.firmwareDevice.chip;
export const selectCurrentDevice = (state: RootState): any => state.firmwareDevice.device;
