import React from "react";
import { useSelector } from "react-redux";
import { userModel } from "..";

const OwnerAccess = ({ id, children }: { id: number; children: React.ReactNode }) => {
    const user = useSelector(userModel.selectUser);
    const isUserAdmin = useSelector(userModel.selectUserIsAdmin);
    if (user.id === id || isUserAdmin) {
        return <>{children}</>;
    } else return <></>;
};

export default OwnerAccess;
