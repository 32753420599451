import { useEspLoaderTerminal } from "./UseEspLoaderTerminal";
import { MAX_BAUDRATE } from "./constants";
import { ESPLoader, Transport } from "./esptool-js/lib";

export const useEsptoolErase = () => {
    const espLoaderTerminal = useEspLoaderTerminal();

    const startEspErase = async (transport: Transport) => {
        let macAddress = null;
        const flashOptions = {
            transport: transport,
            baudrate: MAX_BAUDRATE,
            terminal: espLoaderTerminal,
        } as any;

        const esptool = new ESPLoader(flashOptions);
        try {
            const { mac } = await esptool.main();
            macAddress = mac.toUpperCase();
            await esptool.eraseFlash();
            await esptool.transport.disconnect();
        } catch (error) {
            //await esptool.hardReset();
            console.log("ERROR", error);
            throw error;
        }
        return macAddress;
    };
    return {
        startEspErase,
    };
};
