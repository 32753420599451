import { $api } from "@shared/api/api";

export const getFlashAttemptsHistory = (params?: TParams) =>
    $api.get<TResponse<TFlashAttemptsData>>("api/flash_attempts", { params: params });

export const sendInformationAboutFlash = (data: TFlashInfo) =>
    $api.post<TResponse<TFlashAttemptData>>("api/flash_attempts", data);

export const changeFlashAttemptInfo = (id: number, data: TFlashAttemptData) =>
    $api.put<TResponse<TFlashAttemptData>>(`api/flash_attempts/${id}`, data);

export const deleteFlashAttempt = (id: number) => $api.delete(`api/flash_attempts/${id}`);

export const deleteManyFlashAttempts = (ids: React.Key[]) =>
    $api.post(`api/flash_attempts/delete`, { ids });
