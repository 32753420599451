import { useEspLoaderTerminal } from "./UseEspLoaderTerminal";
import { MAX_BAUDRATE } from "./constants";
import { ESPLoader, Transport } from "./esptool-js/lib";
import CryptoJS from "crypto-js";

export const useEsptoolFlash = () => {
    const espLoaderTerminal = useEspLoaderTerminal();

    const startEspFlash = async (transport: Transport, fileArray: any[]) => {
        try {
            const flashOptions = {
                transport,
                baudrate: MAX_BAUDRATE,
                fileArray,
                flashSize: "keep",
                eraseAll: false,
                compress: true,
                calculateMD5Hash: (image: any) => CryptoJS.MD5(CryptoJS.enc.Latin1.parse(image)),
                terminal: espLoaderTerminal,
            } as any;
            const esptool = new ESPLoader(flashOptions);
            await esptool.main();
            await esptool.writeFlash(flashOptions);
            await esptool.transport.disconnect();
        } catch (e: any) {
            throw new Error();
        }
    };

    return {
        startEspFlash,
    };
};
