import { firmwareDeviceModel } from "@entities/FirmwareDevice";
import { flashAttemptsApi } from "@entities/FlashAttempts";
import { useEsptoolErase, useEsptoolFlash } from "@shared/esptool";
import { ESPLoader, Transport } from "@shared/esptool/esptool-js/lib";
import { PORT_NOT_SELECTED_ERROR, useSerial } from "@shared/serial";
import { useTerminalEmits } from "@shared/ui/Terminal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fillFileArray } from "../lib";

export const useFirmwareCommand = (
    transport: React.MutableRefObject<Transport | undefined>,
    esptool: React.MutableRefObject<ESPLoader | undefined>
) => {
    const dispatch = useDispatch();
    const terminal = useTerminalEmits("firmware");
    const { requestSerialPort, closeSerial } = useSerial(transport, esptool);
    const { startEspErase } = useEsptoolErase();
    const { startEspFlash } = useEsptoolFlash();
    const currentVersion = useSelector(firmwareDeviceModel.selectCurrentVersion);
    const currentDeviceType = useSelector(firmwareDeviceModel.selectCurrentDeviceType);
    const currentDeviceSubType = useSelector(firmwareDeviceModel.selectCurrentDeviceSubType);
    const [isActive, setIsActive] = useState(false);
    const [statusOfFirmwaring, setStatusOfFirmwaring] = useState<"success" | "failed">("failed");

    const getSerial = async () => {
        try {
            terminal.addInfoMessageEmit("Ожидание COM-порта");
            const transport = await requestSerialPort();
            return transport;
        } catch (error: any) {
            if (error.code === PORT_NOT_SELECTED_ERROR) {
                terminal.addErrorMessageEmit("Порт не выбран.");
                throw new Error();
            }
            return null;
        }
    };
    const eraseCommand = async (isFirmwaring?: boolean) => {
        let mac = null;
        try {
            const transport = await getSerial();
            if (transport) {
                terminal.addInfoMessageEmit("Начинаем очистку памяти");
                const macAddress = await startEspErase(transport);
                mac = macAddress;
                terminal.addSuccessMessageEmit("Очистка памяти устройства завершена");
            }
        } catch (error: any) {
            terminal.addErrorMessageEmit(
                "Не удалось выполнить операцию\n" + (error?.message ?? "")
            );
            closeSerial();
            if (isFirmwaring) throw new Error();
        }
        return mac;
    };

    const flashCommand = async () => {
        dispatch(firmwareDeviceModel.setIsFirmwaringOperation(true));
        const transport = await getSerial();
        if (transport)
            try {
                terminal.addInfoMessageEmit("Начинаем прошивку устройства");
                const fileArray = await fillFileArray(currentVersion!);
                await startEspFlash(transport, fileArray);
                terminal.addSuccessMessageEmit("Прошивка устройства завершена");
            } catch (error: any) {
                terminal.addErrorMessageEmit(
                    "Не удалось выполнить операцию\n" + (error?.message ?? "")
                );
                closeSerial();
                throw new Error();
            } finally {
                dispatch(firmwareDeviceModel.setIsFirmwaringOperation(false));
            }
    };

    const firmwareCommand = async () => {
        let mac;
        setIsActive(true);
        dispatch(firmwareDeviceModel.setIsFirmwaringCommand(true));
        try {
            mac = await eraseCommand(true);
            await flashCommand();
            setStatusOfFirmwaring("success");
        } catch (e) {
            setStatusOfFirmwaring("failed");
        } finally {
            if (!!mac) {
                flashAttemptsApi
                    .sendInformationAboutFlash({
                        mac: mac!,
                        type: currentDeviceType!,
                        subtype: currentDeviceSubType!,
                        version: currentVersion!.version,
                        status: statusOfFirmwaring!,
                    })
                    .catch((e: any) => {
                        console.log(e.message);
                    });
            }
            dispatch(firmwareDeviceModel.setIsFirmwaringCommand(false));
            setIsActive(false);
        }
    };

    const eraseClickCommand = async () => {
        setIsActive(true);
        dispatch(firmwareDeviceModel.setIsErasingCommand(true));
        try {
            await eraseCommand();
        } catch (e) {
        } finally {
            console.log("CANCELED");
            setIsActive(false);
            dispatch(firmwareDeviceModel.setIsErasingCommand(false));
        }
    };
    return {
        isActive,
        eraseClickCommand,
        firmwareCommand,
    };
};
