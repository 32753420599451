import { userModel } from "@entities/User";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const useSidebar = (menuItems: SideBarItems[]) => {
    const location = useLocation();
    const [currentMenuItem, setCurrentMenuItem] = useState<string | undefined>(undefined);
    const [currentParentMenuItem, setParentCurrentMenuItem] = useState<string[] | undefined>(
        undefined
    );
    const user = useSelector(userModel.selectUser);
    const navigate = useNavigate();

    useEffect(() => {
        const [parentMenuItem, subMenuItem] = findActiveMenuItem(
            location.pathname,
            menuItems.filter((menu) => checkAccess(menu.roles))
        );
        setCurrentMenuItem(subMenuItem);
        setParentCurrentMenuItem(parentMenuItem ? [parentMenuItem] : undefined);
    }, []);

    const checkAccess = (roles: string[]) => {
        /* @ts-ignore */
        const userRoles = user.capabilities.split(",");
        let access = false;
        roles.forEach((role) => {
            if (userRoles.includes(role)) {
                access = true;
            }
        });
        return access;
    };

    const findActiveMenuItem = (route: string, items: SideBarItems[]) => {
        const searchingValue = route.split("/")[1];
        let parentMenuItemValue = "";

        const findedSubMenuItem = items
            .find((item) => {
                if (item.route === searchingValue) return true;
                if (item.submenus && item.submenus.length > 1) {
                    return item.submenus.find((submenu) => {
                        if (submenu.route === searchingValue) {
                            parentMenuItemValue = item.value;
                            return true;
                        }
                    });
                }
                return false;
            })
            ?.submenus?.find((submenu) => submenu.route === searchingValue)?.value;
        return [parentMenuItemValue, findedSubMenuItem];
    };

    const changeCurrentParrentOpenKeys = (keys: string[]) => {
        if (currentParentMenuItem) {
            setParentCurrentMenuItem(keys);
        }
    };

    const onSubmenuClick = (info: any, value: string, url: string, blanc: any, route: string) => {
        setCurrentMenuItem(value ?? info.key);
        if (!url.includes(window.location.hostname) && process.env.NODE_ENV === "production") {
            window.open(url + route, blanc ? "_blank" : "_self");
        } else {
            navigate(route);
        }
    };

    const onMenuClick = (info: any) => {
        if (!info.url.includes(window.location.hostname) && process.env.NODE_ENV === "production") {
            window.open(info.url + info.route, info.blanc ? "_blank" : "_self");
            //history.push("/" + submenu.route)
        } else {
            navigate(info.route);
        }
    };

    return {
        currentMenuItem,
        currentParentMenuItem,
        findActiveMenuItem,
        changeCurrentParrentOpenKeys,
        setParentCurrentMenuItem,
        setCurrentMenuItem,
        checkAccess,
        onSubmenuClick,
        onMenuClick,
    };
};
