import { FormItem } from "@shared/ui/Form";
import { TFilterInputItem, TInputSelect } from "../../FilterKitTypes";
import { Select } from "antd";

const FilterSelectItem = ({ field, onChange }: TFilterInputItem<TInputSelect>) => {
    const { label, value, name, options } = field;
    const onSelect = (name: string, value: any) => {
        const { onChangeCustom } = field;
        if (onChangeCustom) {
            onChangeCustom(value);
        } else {
            onChange({ [name]: value });
        }
    };

    return (
        <FormItem label={label}>
            <Select
                showSearch
                allowClear
                options={options}
                onChange={(value: any) => {
                    onSelect(name, value);
                }}
                filterOption={(input: string, option) => !!option?.label?.includes(input)}
                value={value}
                style={{ width: "100%" }}
            />
        </FormItem>
    );
};

export default FilterSelectItem;
